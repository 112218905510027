import React from "react";

import closeIcon from "assets/images/close.svg";
import styles from "./styles.module.scss";

type DetailsProps = {
  title?: string;
  onClose: () => void;
  subheader: React.ReactNode;
  children: React.ReactNode;
  headerAction?: React.ReactNode;
};

export const Details = ({
  title,
  subheader,
  children,
  onClose,
  headerAction,
}: DetailsProps) => (
  <div className={styles.details}>
    <header className={styles.header}>
      <div className={styles.title}>{title}</div>
      <div className={styles.actions}>
        {headerAction}
        <img onClick={onClose} src={closeIcon} alt="Close card" />
      </div>
    </header>
    {subheader}
    <hr />
    <div className={styles.content}>{children}</div>
  </div>
);
