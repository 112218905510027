import React, { createContext, ReactNode, useState } from "react";

import { Notification } from "./Notification";

export type NotificationType = {
  title: string;
  text?: string;
  isError?: boolean;
};

export const NotificationContext = createContext<{
  setNotification: (notification: NotificationType) => void;
} | null>(null);

type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notification, setNotification] = useState<NotificationType>({
    title: "",
  });

  return (
    <NotificationContext.Provider value={{ setNotification }}>
      {children}
      <Notification {...notification} />
    </NotificationContext.Provider>
  );
};
