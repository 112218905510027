// Survicate (https://survicate.com/) is used for NPS surveys
// Survicate is initialized in every environment - it is configured on the survicate side to whitelist only the production domain

// the _sva, _svc, and _svd properties are added to window via a survicate script in index.html
// see global-env.d.ts for the type definitions - we aren't using all of the properties possible. if we need to add something to the types, it is okay.
// more info about the javacript API can be found here: https://developers.survicate.com/javascript/

export default {
  sva: window._sva,
  svc: window._svc,
  svd: window._svd,
};
