import React from "react";
import cn from "classnames";

import { INVOICE_PAYMENT_STATUSES } from "utils/constants/INVOICE_PAYMENT_STATUSES";

import styles from "./styles.module.scss";

type DetailsCardProps = {
  amount: string;
  status?: INVOICE_PAYMENT_STATUSES;
  setDetailsOpen?: () => void;
  children: React.ReactNode;
  isOverdue?: boolean;
  className?: string;
};

export const DetailsCard = ({
  amount,
  status,
  isOverdue,
  setDetailsOpen,
  children,
  className,
}: DetailsCardProps) => (
  <section className={cn(styles.detailsCard, className)}>
    <header className={styles.header}>
      <p className={styles.amount}>{amount}</p>
      {status && (
        <span
          className={cn(styles.status, {
            [styles.paid]: status === INVOICE_PAYMENT_STATUSES.PAID,
            [styles.overdue]: isOverdue,
          })}
        >
          {isOverdue ? "Overdue" : status}
        </span>
      )}
    </header>
    {setDetailsOpen && (
      <p className={styles.button} onClick={setDetailsOpen}>
        {"See invoice details >"}
      </p>
    )}
    {children}
  </section>
);
