import { useContext } from "react";

import { NotificationContext } from "utils/contexts/notification";

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (context === null) {
    throw new Error("useNotification must be within NotificationProvider");
  }

  return context;
};
