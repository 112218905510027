import React from "react";
import cn from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

type LoaderProps = {
  className?: string;
  blocking?: boolean;
};

export const Loader = ({ className, blocking }: LoaderProps) => (
  <div className={cn(styles.loader, className, blocking && styles.blocking)}>
    <FontAwesomeIcon icon={faSpinner} spin />
  </div>
);
