import React from "react";

import {PickupDeliveryOrderSummary} from "./PickupDeliveryOrderSummary";

import {toDollars} from "utils/functions/toDollars";
import {displayPromotionAmount} from "utils/functions/displayPromotionAmount";
import {ORDER_STATUSES} from "utils/constants/ORDER_STATUSES";
import {OrderDetails} from "utils/types/OrderDetails";

import styles from "./styles.module.scss";

type PaymentSummaryProps = {orderDetails: OrderDetails};
export const PaymentSummary = ({orderDetails}: PaymentSummaryProps) => (
  <div className={styles.footer}>
    {orderDetails.isIntakeComplete && (
      <>
        <div className={styles.paidInfo}>
          <p>Subtotal</p>
          <p>{toDollars(orderDetails.orderTotal)}</p>
        </div>
        {orderDetails.promotionIds.length
          ? orderDetails.promotions.map(
              (promotion) =>
                promotion.promoDetails && (
                  <div className={styles.paidInfo}>
                    <p>{displayPromotionAmount(promotion.promoDetails)} promo applied</p>
                    <p>-{toDollars(promotion.promoDetails.orderPromotionAmount)}</p>
                  </div>
                )
            )
          : null}
        {orderDetails.subscription &&
          orderDetails.subscription.recurringDiscountInPercent > 0 &&
          orderDetails.recurringDiscountInCents > 0 && (
            <div className={styles.paidInfo}>
              <p>
                Recurring Order Discount (
                {orderDetails.subscription.recurringDiscountInPercent}%)
              </p>
              <p>-{toDollars(orderDetails.recurringDiscountInCents / 100)}</p>
            </div>
          )}
        {orderDetails.isTaxable && (
          <div className={styles.paidInfo}>
            <p>Tax</p>
            <p>{toDollars(orderDetails.taxAmount)}</p>
          </div>
        )}
        {orderDetails.convenienceFee > 0 && (
          <div className={styles.paidInfo}>
            <p>Service fee</p>
            <p>{toDollars(orderDetails.convenienceFee)}</p>
          </div>
        )}
        {orderDetails.creditAmount > 0 && (
          <div className={styles.paidInfo}>
            <p>Credit applied</p>
            <p>-{toDollars(orderDetails.creditAmount)}</p>
          </div>
        )}
        {orderDetails.tipAmount !== 0 && (
          <div className={styles.paidInfo}>
            <p>
              Tip
              {!orderDetails.tipOption.startsWith("$") && ` (${orderDetails.tipOption})`}
            </p>
            <p>{toDollars(orderDetails.tipAmount)}</p>
          </div>
        )}
      </>
    )}
    {orderDetails.subscription &&
      orderDetails.subscription.recurringDiscountInPercent > 0 &&
      !orderDetails.isIntakeComplete && (
        <div className={styles.paidInfo}>
          <p>Recurring Order Discount </p>
          <p>-{orderDetails.subscription.recurringDiscountInPercent}% off</p>
        </div>
      )}
    {(orderDetails.delivery.deliveryProvider || orderDetails.pickup.deliveryProvider) && (
      <PickupDeliveryOrderSummary orderDetails={orderDetails} />
    )}
    {orderDetails.isIntakeComplete &&
      orderDetails.status !== ORDER_STATUSES.CANCELLED && (
        <>
          <div className={styles.paidInfoTitle}>
            <p>Total Paid</p>
            <p>{toDollars(orderDetails.totalPaid)}</p>
          </div>

          <div className={styles.dueInfo}>
            <p>Total Due</p>
            <p>{toDollars(orderDetails.balanceDue)}</p>
          </div>
        </>
      )}
    {orderDetails.isIntakeComplete &&
      orderDetails.status === ORDER_STATUSES.CANCELLED &&
      orderDetails.refundableAmount === 0 && (
        <div className={styles.paidInfo}>
          <p className={styles.canceledText}>Canceled</p>
          <p>{toDollars(0)}</p>
        </div>
      )}
  </div>
);
