import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { verifySessionData } from "api/verifySessionData";

type SessionDataRes = {
  isPreview: boolean;
  sessionData: string;
};

type Error = {
  response: {
    status: string;
    data: {
      error: string;
    };
  };
};

export const Landing = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionData =
      searchParams.get("sessionData") || searchParams.get("token");
    verifySessionData<SessionDataRes>(sessionData)
      .then((res) => {
        sessionStorage.setItem("sessionData", res.data.sessionData);
        const redirectUrl = searchParams.get("redirectUrl");
        if (redirectUrl) {
          sessionStorage.setItem("isPreview", res.data.isPreview.toString());
          window.location.assign(redirectUrl);
        } else {
          navigate("/error", {
            state: {
              error: "500",
              message: "Missing redirect url",
            },
          });
        }
      })
      .catch((error: Error) =>
        navigate("/error", {
          state: {
            error: error.response.status,
            message: error.response.data.error,
          },
        })
      );
  }, [searchParams, navigate]);

  return <></>;
};
