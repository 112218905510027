import React from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import classNames from "classnames";

import {Order} from "utils/types/Order";
import {toDollars} from "utils/functions/toDollars";

import styles from "./styles.module.scss";

type InvoiceOrdersForCustomerProps = {
  orders: Array<Order>;
  customerName?: string;
  primaryCustomerName: string;
  customerOrdersTotalInDollars: string;
  withCustomerNameTitle?: boolean;
  setActiveOrderUuid: (uuid: string) => void;
};

export const InvoiceOrdersForCustomer = ({
  orders,
  customerName, // provided only for sub-customers
  customerOrdersTotalInDollars,
  primaryCustomerName,
  withCustomerNameTitle,
  setActiveOrderUuid,
}: InvoiceOrdersForCustomerProps) => {
  const {invoicingUiUpdates} = useFlags();

  return (
    <>
      {withCustomerNameTitle && (
        <tr className={styles.customerNameTitle}>
          <span className={styles.fontBold}>{customerName || primaryCustomerName}</span>{" "}
          <span className={styles.fontItalic}>
            ({customerName ? `Billing to ${primaryCustomerName}` : `Primary Account`})
          </span>
          : {customerOrdersTotalInDollars}
        </tr>
      )}
      {orders.length ? (
        orders.map((order) => (
          <tr className={styles.invoiceOrdersRow} key={order.orderCodeWithPrefix}>
            <td>
              <p
                className={styles.orderNumber}
                onClick={() => setActiveOrderUuid(order.uuid)}
              >
                {order.orderCodeWithPrefix}
              </p>
            </td>
            <td
              className={classNames(styles.intakeDate, {
                [styles.textAlignStart]: invoicingUiUpdates,
              })}
            >
              {order.intakeDate}
            </td>
            <td>
              <p
                className={classNames(styles.balanceDueNet, {
                  [styles.textAlignStart]: invoicingUiUpdates,
                })}
              >
                {order.netOrderTotal}
              </p>
              <p
                className={classNames(styles.balanceDueTaxes, {
                  [styles.textAlignStart]: invoicingUiUpdates,
                })}
              >
                incl. {order.tax} tax
              </p>
            </td>
            {invoicingUiUpdates && (
              <td>
                <p className={styles.balanceDueNet}>{toDollars(order.balanceDue)}</p>
              </td>
            )}
          </tr>
        ))
      ) : (
        <tr className={styles.noOrders}>No orders</tr>
      )}
    </>
  );
};
