// Sentry (https://sentry.io/welcome/) is used to capture and alert on errors so that we can be more proactive about finding and fixing issues

import * as Sentry from "@sentry/browser";

export function initSentry() {
  Sentry.init({
    dsn: "https://062e2d9f6f004ff5be6cd1c722d354f4@o464187.ingest.sentry.io/4504282529464320",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: import.meta.env.VITE_ENV,
    release: import.meta.env.VITE_COMMIT_HASH,
  });
}
