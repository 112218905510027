import React, { useState, useEffect } from "react";
import cn from "classnames";

import { DetailsCard } from "components/DetailsCard";
import { Loader } from "components/common/Loader";
import { SideCard } from "components/SideCard";
import { PaymentForm } from "components/PaymentForm";
import { InvoiceDetailsCardInfo } from "components/InvoiceView/InvoiceDetailsCardInfo";
import { DownloadButton } from "components/InvoiceView/DownloadButton";
import Fullstory from "third-party/fullstory";
import Survicate from "third-party/survicate";

import { useInvoiceQuery } from "./useInvoiceQuery";
import { useUpdateInvoiceStatus } from "./useUpdateInvoiceStatus";
import { useLaunchdarkly } from "./useLaunchdarkly";

import { INVOICE_PAYMENT_STATUSES } from "utils/constants/INVOICE_PAYMENT_STATUSES";

import styles from "./styles.module.scss";

export const InvoiceView = () => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  const {
    isInvoiceLoading,
    invoice,
    businessDetails,
    isUuidInvalid,
    setInvoiceChanged,
    id,
  } = useInvoiceQuery();

  useEffect(
    function addBusinessIdToTracking() {
      const businessId = businessDetails?.businessId;
      if (businessId) {
        Fullstory.setUserVars({
          businessId,
        });

        if (Survicate?.sva) {
          Survicate.sva.setVisitorTraits({
            businessId,
          });
        }
      }
    },
    [businessDetails?.businessId]
  );

  useEffect(
    function addInvoiceCodeToTracking() {
      const invoiceCode = invoice?.invoiceCode;
      if (invoiceCode) {
        Fullstory.setUserVars({
          invoiceCode,
        });

        if (Survicate?.sva) {
          Survicate.sva.setVisitorTraits({
            invoiceCode,
          });
        }
      }
    },
    [invoice?.invoiceCode]
  );

  useUpdateInvoiceStatus(invoice);

  useLaunchdarkly(businessDetails?.businessId);

  const openInvoicePdf = () =>
    window.open(
      `${import.meta.env.VITE_CENTS_API_URL}/public/invoices/${id}/print/pdf`,
      "_blank",
      "noopener"
    );

  if (isInvoiceLoading) {
    return (
      <div className={styles.app}>
        <Loader className={styles.loader} />
      </div>
    );
  }

  if (!invoice || invoice?.isDeleted || isUuidInvalid || !businessDetails) {
    return null;
  }

  return (
    <div className={styles.app}>
      <aside className={styles.pdfDownload}>
        <DownloadButton
          downloadAction={openInvoicePdf}
          isCollapsed={isDetailsOpen}
        />
      </aside>

      <main className={cn(styles.main, isDetailsOpen && styles.open)}>
        <img src={businessDetails.logoUrl} className={styles.logo} alt="logo" />
        <DetailsCard
          amount={invoice.invoiceTotal}
          status={invoice.paymentStatus}
          isOverdue={invoice.isOverdue}
          setDetailsOpen={() => setDetailsOpen(!isDetailsOpen)}
          className={cn(
            invoice.paymentStatus === INVOICE_PAYMENT_STATUSES.PAID &&
              styles.paidStatus
          )}
        >
          <InvoiceDetailsCardInfo invoice={invoice} />
        </DetailsCard>
        {invoice.paymentStatus === INVOICE_PAYMENT_STATUSES.UNPAID && (
          <PaymentForm
            businessPrimaryColor={businessDetails.businessPrimaryColor}
            invoiceCode={invoice.invoiceCode}
            setInvoiceChanged={setInvoiceChanged}
          />
        )}
      </main>
      <aside className={cn(styles.aside, isDetailsOpen && styles.asideOpen)}>
        <SideCard
          invoice={invoice}
          isDetailsOpen={isDetailsOpen}
          setDetailsOpen={setDetailsOpen}
          downloadAction={openInvoicePdf}
        />
      </aside>
    </div>
  );
};
