import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

type CardSectionProps = {
  className?: string;
  title?: string;
  imgSrc?: string;
  button?: React.ReactNode;
  children: React.ReactNode;
};

export const CardSection = ({
  className,
  title,
  imgSrc,
  button,
  children,
}: CardSectionProps) => (
  <section className={cx(styles.section, className)}>
    {title && (
      <header className={styles.header}>
        <div>
          <img src={imgSrc} alt={title} />
          <p>{title}</p>
        </div>
        {button}
      </header>
    )}
    {children}
  </section>
);
