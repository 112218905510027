import React from "react";
import cn from "classnames";

import { INVOICE_PAYMENT_STATUSES } from "utils/constants/INVOICE_PAYMENT_STATUSES";
import { InvoiceSummary } from "utils/types/InvoiceSummary";

import styles from "./styles.module.scss";

type InvoiceOrdersSummaryProps = {
  summary?: Partial<InvoiceSummary>;
  invoicePaymentStatus: INVOICE_PAYMENT_STATUSES;
};

export const InvoiceOrdersSummary = ({
  summary = {},
  invoicePaymentStatus,
}: InvoiceOrdersSummaryProps) => {
  const isPaid = invoicePaymentStatus === INVOICE_PAYMENT_STATUSES.PAID;
  const isPending = invoicePaymentStatus === INVOICE_PAYMENT_STATUSES.PENDING;

  return (
    <div className={styles.summaryGrid}>
      <p>Subtotal</p>
      <p className={styles.value}>{summary.subtotalWithConvenienceFee}</p>
      <p>Tax</p>
      <p className={styles.value}>{summary.tax}</p>
      <p>Total</p>
      <p className={styles.value}>{summary.total}</p>
      <span>
        <p className={cn(isPaid && styles.success)}>Amount Paid</p>
        {isPending && <p className={styles.italic}>Pending</p>}
      </span>
      <p className={cn(styles.value, isPaid && styles.success)}>
        {summary.amountPaid}
      </p>
      {!isPaid && (
        <>
          <p>Balance Due</p>
          <p className={styles.value}>{summary.balanceDue}</p>
        </>
      )}
    </div>
  );
};
