import {toDollars} from "utils/functions/toDollars";
import {toDollarsOrFree} from "utils/functions/toDollarsOrFree";
import {ORDER_ITEM_CATEGORIES} from "utils/constants/ORDER_ITEM_CATEGORIES";
import {OrderItem} from "utils/types/OrderDetails";

export const getAmountPerUnit = ({
  serviceCategory,
  pricingType,
  price,
  itemTotal,
  count,
  hasMinPrice,
  minimumPrice,
  minimumQuantity,
}: OrderItem) => {
  if (serviceCategory === ORDER_ITEM_CATEGORIES.DELIVERY) {
    return toDollarsOrFree(itemTotal);
  }

  const isFixedPrice = pricingType === ORDER_ITEM_CATEGORIES.FIXED_PRICE;

  const quantityUnit = isFixedPrice ? "unit" : "lb";
  const quantityText = isFixedPrice ? `${count} x` : `${count} lbs`;

  const minimumPriceText =
    hasMinPrice && minimumPrice && pricingType === ORDER_ITEM_CATEGORIES.PER_POUND
      ? ` | First ${minimumQuantity} lb${minimumQuantity > 1 ? "s" : ""} ${toDollars(
          minimumPrice
        )}`
      : "";

  return `${quantityText} (${toDollars(price)} / ${quantityUnit}${minimumPriceText})`;
};
