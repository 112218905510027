import React from "react";
import {useFlags} from "launchdarkly-react-client-sdk";

import {Order} from "utils/types/Order";

import styles from "./styles.module.scss";
import {InvoiceOrdersForCustomer} from "components/InvoiceOrdersForCustomer";
import {CustomerWithOrders} from "utils/types/InvoiceSummary";
import {TaskDescriptionIcon} from "assets/images";
import classNames from "classnames";

type InvoiceOrdersProps = {
  orders: Array<Order>;
  customerOrdersTotalInDollars: string;
  primaryCustomerName: string;
  childCustomers: CustomerWithOrders[];
  setActiveOrderUuid: (uuid: string) => void;
};

export const InvoiceOrders = ({
  orders,
  customerOrdersTotalInDollars,
  primaryCustomerName,
  childCustomers,
  setActiveOrderUuid,
}: InvoiceOrdersProps) => {
  const {invoicingUiUpdates} = useFlags();

  return (
    <>
      <p className={styles.summaryTitle}>
        <TaskDescriptionIcon /> Summary
      </p>
      <table>
        <tbody>
          <tr className={styles.invoiceOrdersHeader}>
            <th className={styles.orderNumber}>ORDER #</th>
            <th
              className={classNames({
                [styles.textAlignStart]: invoicingUiUpdates,
              })}
            >
              INTAKE DATE
            </th>
            {invoicingUiUpdates ? (
              <>
                <th className={styles.textAlignStart}>ORDER TOTAL</th>
                <th>BALANCE DUE</th>
              </>
            ) : (
              <th>AMOUNT</th>
            )}
          </tr>
          <InvoiceOrdersForCustomer
            customerOrdersTotalInDollars={customerOrdersTotalInDollars}
            orders={orders}
            setActiveOrderUuid={setActiveOrderUuid}
            primaryCustomerName={primaryCustomerName}
            withCustomerNameTitle={!!childCustomers.length}
          />
          {childCustomers.map((childCustomer) => (
            <InvoiceOrdersForCustomer
              customerOrdersTotalInDollars={childCustomer.total}
              orders={childCustomer.orders}
              setActiveOrderUuid={setActiveOrderUuid}
              primaryCustomerName={primaryCustomerName}
              customerName={childCustomer.customerName}
              withCustomerNameTitle
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
