import React from "react";

import { DriverTip } from "./DriverTip";

import { getFinalSubsidy } from "utils/functions/getFinalSubsidy";
import { toDollars } from "utils/functions/toDollars";

import styles from "./styles.module.scss";

type OnDemandOrderSummaryProps = {
  deliveryFee: number;
  driverTip: number;
  orderDelivery: {
    totalDeliveryCost: number;
    subsidyInCents: number;
    thirdPartyDeliveryCostInCents: number;
  };
  type: string;
};

export const OnDemandOrderSummary = ({
  deliveryFee,
  driverTip,
  orderDelivery,
  type,
}: OnDemandOrderSummaryProps) => {
  const { totalDeliveryCost, subsidyInCents, thirdPartyDeliveryCostInCents } =
    orderDelivery;

  const thirdPartyDeliveryCost = thirdPartyDeliveryCostInCents / 100;
  const laundrySubsidy = subsidyInCents / 100;
  const finalLaundrySubsidy = getFinalSubsidy(
    laundrySubsidy,
    thirdPartyDeliveryCost
  );
  const tripCost = totalDeliveryCost + finalLaundrySubsidy;

  return (
    <div className={styles.full}>
      <div className={styles.onDemandPaidInfo}>
        <p>On-Demand {type}</p>
        <p>{toDollars(deliveryFee)}</p>
      </div>
      {tripCost !== 0 && (
        <div className={styles.italicText}>
          <p>Trip cost: {toDollars(tripCost)}</p>
        </div>
      )}
      {laundrySubsidy !== 0 && (
        <div className={styles.italicSubsidyText}>
          <p>Subsidy: -{toDollars(finalLaundrySubsidy)}</p>
        </div>
      )}
      {driverTip !== 0 && <DriverTip prefix={type} driverTip={driverTip} />}
    </div>
  );
};
