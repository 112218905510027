import React from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatAsUSAPhoneNumber } from "utils/functions/formatAsUSAPhoneNumber";
import { OrderDetails } from "utils/types/OrderDetails";

import styles from "./styles.module.scss";

type OrderSummaryHeaderProps = {
  orderDetails: OrderDetails;
  onBack: () => void;
};

export const OrderSummaryHeader = ({
  orderDetails,
  onBack,
}: OrderSummaryHeaderProps) => (
  <header className={styles.header}>
    <div className={styles.wrapper}>
      <div className={styles.backBtnContainer} onClick={onBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <p className={styles.backBtn}>Back</p>
      </div>
      <p className={styles.title}>Order #{orderDetails.orderCodeWithPrefix}</p>
    </div>
    <div className={styles.info}>
      <p className={styles.infoText}>{orderDetails.customer.fullName}</p>
      <p className={styles.infoText}>
        {formatAsUSAPhoneNumber(orderDetails.customer.phoneNumber)}
      </p>
      <div className={styles.storeInfo}>
        <p>{orderDetails.store.name}</p>
        <p>{orderDetails.store.address}</p>
        <p>{orderDetails.store.city}</p>
        <p>
          {orderDetails.store.state} {orderDetails.store.zipCode}
        </p>
      </div>
    </div>
  </header>
);
