import cn from "classnames";
import styles from "components/InvoiceView/download-button.module.scss";
import {DownloadIcon} from "assets/images";

type DownloadButtonProps = {
  downloadAction: () => void;
  isCollapsed?: boolean;
  text?: string;
};

export function DownloadButton({
  downloadAction,
  isCollapsed = false,
  text = "Download PDF",
}: DownloadButtonProps) {
  return (
    <button
      type="button"
      onClick={downloadAction}
      className={cn({
        [styles.buttonBase]: true,
        [styles.buttonCollapsed]: isCollapsed,
      })}
    >
      <DownloadIcon className={styles.buttonIcon} />
      <span className={styles.buttonCopy}>{text}</span>
    </button>
  );
}
