import React from "react";

import { getEachWayFee } from "utils/functions/getEachWayFee";
import { toDollarsOrFree } from "utils/functions/toDollarsOrFree";
import { getPickupOrDelivery } from "utils/functions/getPickupOrDelivery";
import { DELIVERY_PROVIDERS } from "utils/constants/DELIVERY_PROVIDERS";

import styles from "./styles.module.scss";

type StandardSummaryProps = {
  pickupProvider?: DELIVERY_PROVIDERS;
  deliveryProvider?: DELIVERY_PROVIDERS;
  pickupDeliveryFee: number;
  returnDeliveryFee: number;
};

export const StandardSummary = ({
  pickupProvider,
  deliveryProvider,
  pickupDeliveryFee,
  returnDeliveryFee,
}: StandardSummaryProps) => (
  <div className={styles.pickupDeliveryInfo}>
    <div>
      <p>
        {getPickupOrDelivery<string>({
          isPickup: Boolean(pickupProvider),
          isDelivery: Boolean(deliveryProvider),
          both: "Pickup & Delivery",
          pickup: "Pickup",
          delivery: "Delivery",
        })}
      </p>
      {pickupDeliveryFee !== 0 && returnDeliveryFee !== 0 && (
        <p className={styles.textPaddingLeft}>
          ({getEachWayFee(pickupDeliveryFee, returnDeliveryFee)})
        </p>
      )}
    </div>
    <p>
      {toDollarsOrFree(
        getPickupOrDelivery<number>({
          isPickup: Boolean(pickupProvider),
          isDelivery: Boolean(deliveryProvider),
          both: pickupDeliveryFee + returnDeliveryFee,
          pickup: pickupDeliveryFee,
          delivery: returnDeliveryFee,
        })
      )}
    </p>
  </div>
);
