import { toDollars } from "utils/functions/toDollars";
import { toDollarsOrFree } from "utils/functions/toDollarsOrFree";
import { ORDER_ITEM_CATEGORIES } from "utils/constants/ORDER_ITEM_CATEGORIES";
import { OrderItem } from "utils/types/OrderDetails";

export const calculateItemTotal = ({
  itemTotal,
  serviceCategory,
}: OrderItem) => {
  if (serviceCategory === ORDER_ITEM_CATEGORIES.DELIVERY) {
    return toDollarsOrFree(itemTotal);
  }

  return toDollars(itemTotal);
};
