export const PHONE_NUMBER_FORMAT_REGEXP = /^(\d{3})(\d{3})(\d{4})$/;
export const NOT_DIGITS_REGEXP = /\D/g;

export const formatAsUSAPhoneNumber = (phoneNumberString?: string) => {
  const cleanedPhoneNumber = `${phoneNumberString}`.replace(
    NOT_DIGITS_REGEXP,
    ""
  );

  const match = cleanedPhoneNumber.match(PHONE_NUMBER_FORMAT_REGEXP);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};
