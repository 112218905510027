import React from "react";
import { useLocation } from "react-router-dom";

import { InvoiceResponseType } from "components/InvoiceView/useInvoiceQuery";
import { formatAsUSAPhoneNumber } from "utils/functions/formatAsUSAPhoneNumber";

import styles from "./styles.module.scss";

type Location = {
  state: InvoiceResponseType;
};

export const InvalidPage = () => {
  const { state } = useLocation() as Location;

  return (
    <main className={styles.main}>
      <img
        src={state.businessDetails.logoUrl}
        className={styles.logo}
        alt="logo"
      />
      <div className={styles.card}>
        <p className={styles.description}>Link no longer valid</p>
        <p className={styles.contact}>
          Please contact {state.invoice.from} for more information:
          <br />
          {formatAsUSAPhoneNumber(state.businessDetails.contactTelephone)}.
        </p>
      </div>
    </main>
  );
};
