import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { updateInvoiceStatus } from "api/invoices";
import { LOGGER_STATES } from "utils/constants/LOGGER_STATES";
import { INVOICE_STATUSES } from "utils/constants/INVOICE_STATUSES";
import { Invoice } from "utils/types/Invoice";

const getLoggerState = (isPreview: string | null) => {
  if (isPreview === "true") {
    return LOGGER_STATES.OFF;
  }

  return LOGGER_STATES.ON;
};

export const useUpdateInvoiceStatus = (invoice?: Invoice) => {
  const { id } = useParams();

  const { mutate: updateInvoiceStatusMutation } = useMutation({
    mutationFn: async ({
      loggerState,
      status,
    }: {
      loggerState: string;
      status: INVOICE_STATUSES;
    }) => {
      await updateInvoiceStatus(id, loggerState, status)();
      sessionStorage.removeItem("isPreview");
    },
  });

  useEffect(() => {
    const loggerState = getLoggerState(sessionStorage.getItem("isPreview"));
    if (loggerState === LOGGER_STATES.ON && invoice?.invoiceCode) {
      updateInvoiceStatusMutation({
        loggerState,
        status: INVOICE_STATUSES.OPENED
      });
    }
  }, [invoice, updateInvoiceStatusMutation]);

  return null;
};
