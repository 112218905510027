import { mutation, query } from "api/httpClient";
import { INVOICE_STATUSES } from "utils/constants/INVOICE_STATUSES";
import { PAYMENT_METHOD } from "utils/constants/STRIPE_PAYMENT_METHODS";

export const getOrderDetails = <T>(invoiceId = "", activeOrderUuid: string) => {
  return query<T>(
    `/commercial-customer/invoices/${invoiceId}/orders/${activeOrderUuid}`
  );
};

export const updateInvoiceStatus = (
  invoiceId = "",
  loggerState: string,
  status: INVOICE_STATUSES
) => {
  return mutation({
    url: `/commercial-customer/invoices/${invoiceId}/update-status`,
    data: { loggerState, status },
  });
};

export const getSummaryOrders = <T>(invoiceId = "") => {
  return query<T>(`/commercial-customer/invoices/${invoiceId}/summary-orders`);
};

export const getInvoice = <T>(invoiceId = "") => {
  return query<T>(`/commercial-customer/invoices/${invoiceId}`);
};

export const getPaymentIntent = <T>(invoiceId = "") => {
  return mutation<T>({
    url: `/commercial-customer/invoices/${invoiceId}/payment-intents`,
  });
};

export const updatePaymentApplicationFee = <T>(
  paymentIntentId = "",
  paymentMethod: PAYMENT_METHOD
) => {
  return mutation<T>({
    method: "patch",
    url: `/commercial-customer/invoices/payment-intents/${paymentIntentId}/update-application-fee`,
    data: { paymentMethod },
  });
};
