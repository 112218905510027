import React, {useState} from "react";

import {OrderSummary} from "components/OrderSummary";
import {Details} from "components/Details";
import {DetailsCard} from "components/DetailsCard";
import {InvoiceOrders} from "components/InvoiceOrders";
import {InvoiceOrdersSummary} from "components/InvoiceOrdersSummary";
import {CardSection} from "components/common/CardSection";
import {Loader} from "components/common/Loader";
import {ErrorPage} from "components/common/ErrorPage";

import {useSummaryQuery} from "./useSummaryQuery";
import {Invoice} from "utils/types/Invoice";
import {INVOICE_PAYMENT_STATUSES} from "utils/constants/INVOICE_PAYMENT_STATUSES";

import pencilIcon from "assets/images/pencil.svg";
import closeIcon from "assets/images/close.svg";
import styles from "./styles.module.scss";
import detailsCardStyles from "components/DetailsCard/styles.module.scss";
import {DownloadButton} from "components/InvoiceView/DownloadButton";

type SideCardProps = {
  isDetailsOpen: boolean;
  invoice: Invoice;
  setDetailsOpen: (value: boolean) => void;
  downloadAction: () => void;
};

export const SideCard = ({
  invoice,
  isDetailsOpen,
  setDetailsOpen,
  downloadAction,
}: SideCardProps) => {
  const [activeOrderUuid, setActiveOrderUuid] = useState("");

  const {isSummaryLoading, summary, isSummaryError, summaryError} =
    useSummaryQuery(isDetailsOpen);

  const onClose = () => {
    setDetailsOpen(false);
    setActiveOrderUuid("");
  };

  if (activeOrderUuid) {
    return (
      <OrderSummary
        activeOrderUuid={activeOrderUuid}
        onBack={() => setActiveOrderUuid("")}
        onClose={onClose}
      />
    );
  }

  if (isSummaryError && summaryError) {
    const {code, message, response} = summaryError;
    return (
      <>
        <ErrorPage
          error={response?.status ?? code}
          message={response?.data.error ?? message}
        />
        <img
          className={styles.closeIcon}
          onClick={() => setDetailsOpen(false)}
          src={closeIcon}
          alt="Close card"
        />
      </>
    );
  }

  if (isSummaryLoading) {
    return <Loader />;
  }

  if (!summary) {
    return null;
  }

  return (
    <Details
      title={invoice.invoiceCode}
      onClose={() => setDetailsOpen(false)}
      headerAction={<DownloadButton text="Download" downloadAction={downloadAction} />}
      subheader={
        <DetailsCard amount={invoice.invoiceTotal} className={styles.details}>
          <div className={detailsCardStyles.info}>
            <p className={detailsCardStyles.data}>Invoice Date</p>
            <p className={detailsCardStyles.value}>{invoice.createdAt}</p>
            <p className={detailsCardStyles.data}>Orders Between</p>
            <p
              className={detailsCardStyles.value}
            >{`${summary.between.from} - ${summary.between.to}`}</p>
            {invoice.paymentStatus === INVOICE_PAYMENT_STATUSES.PAID ? (
              <>
                <p className={detailsCardStyles.data}>Paid</p>
                <p className={detailsCardStyles.value}>{invoice.paidDate}</p>
              </>
            ) : (
              <>
                <p className={detailsCardStyles.data}>Due</p>
                <p className={detailsCardStyles.value}>{invoice.dueDate}</p>
              </>
            )}
          </div>
        </DetailsCard>
      }
    >
      <CardSection>
        <InvoiceOrders
          orders={summary.orders}
          customerOrdersTotalInDollars={summary.customerOrdersTotal}
          primaryCustomerName={summary.customerName}
          childCustomers={summary.childCustomers}
          setActiveOrderUuid={setActiveOrderUuid}
        />
        <InvoiceOrdersSummary
          summary={summary}
          invoicePaymentStatus={invoice.paymentStatus}
        />
      </CardSection>
      {invoice.invoiceMemo && (
        <CardSection title="MEMO" imgSrc={pencilIcon}>
          <p className={styles.sectionRowValue}>{invoice.invoiceMemo}</p>
        </CardSection>
      )}
    </Details>
  );
};
