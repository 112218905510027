import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Landing } from "components/Landing";
import { InvoiceView } from "components/InvoiceView";
import { ErrorPage } from "components/common/ErrorPage";
import { InvalidPage } from "components/InvalidPage";

export const App = () => (
  <Routes>
    <Route path="/" element={<Navigate to="landing" />} />
    <Route path="/auth" element={<Landing />} />
    <Route path="/landing" element={<Landing />} />
    <Route path="/invoices/:id" element={<InvoiceView />} />
    <Route path="/invalid" element={<InvalidPage />} />
    <Route path="/error" element={<ErrorPage />} />
  </Routes>
);
