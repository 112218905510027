import React from "react";
import { useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

type Location = {
  state?: {
    error: number;
    message: string;
  };
};

type ErrorPageProps = {
  error?: string | number;
  message?: string;
};

export const ErrorPage = (props: ErrorPageProps) => {
  const { state } = useLocation() as Location;
  const error = props.error ?? state?.error;
  const message = props.message ?? state?.message ?? "Unknown Error";

  return (
    <div className={styles.errorPage}>
      <h1>Error {error}</h1>
      <h2>{message}</h2>
    </div>
  );
};
