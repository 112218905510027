import React from "react";
import * as Sentry from "@sentry/browser";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, { componentStack}: React.ErrorInfo) {
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
  }

  render() {
    return this.props.children;
  }
}
