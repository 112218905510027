import { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
export const useLaunchdarkly = (businessId?: number) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (businessId) {
      void ldClient?.identify({
        key: String(businessId),
        custom: { businessId },
      });
    }
  }, [ldClient, businessId]);

  return null;
};
