type getPickupOrDeliveryProps<T> = {
  isPickup: boolean;
  isDelivery: boolean;
  both: T;
  pickup: T;
  delivery: T;
};

export const getPickupOrDelivery = <T>({
  isPickup,
  isDelivery,
  both,
  pickup,
  delivery,
}: getPickupOrDeliveryProps<T>) => {
  if (isPickup && isDelivery) {
    return both;
  }

  if (isPickup) {
    return pickup;
  }

  if (isDelivery) {
    return delivery;
  }
};
