import React, { useEffect } from "react";
import cn from "classnames";

import { Modal } from "reactstrap";

import { useToggle } from "utils/hooks/useToggle";
import { NotificationType } from "./index";

import successImg from "assets/images/success.svg";
import errorImg from "assets/images/error.svg";
import closeImg from "assets/images/close.svg";
import styles from "./styles.module.scss";

const DEFAULT_HIDE_TIME = 3000;

export const Notification = (props: NotificationType) => {
  const { title, text, isError = false } = props;

  const { isOpen, setIsOpen, toggle } = useToggle();

  useEffect(() => {
    if (props.title) {
      setIsOpen(true);
    }
  }, [props, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(toggle, DEFAULT_HIDE_TIME);

      return () => clearTimeout(timer);
    }
  }, [isOpen, toggle]);

  return (
    <Modal
      isOpen={isOpen}
      backdrop={false}
      className={styles.notification}
      contentClassName={cn(
        styles.content,
        styles[isError ? "error" : "success"]
      )}
    >
      <img alt="status icon" src={isError ? errorImg : successImg} />
      <p className={styles.text}>
        <b>{title}</b>&nbsp;{text}
      </p>
      <img
        className={styles.closeIcon}
        alt="close icon"
        src={closeImg}
        onClick={toggle}
      />
    </Modal>
  );
};
