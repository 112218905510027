import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { getOrderDetails } from "api/invoices";
import { OrderDetails } from "utils/types/OrderDetails";

export const useOrderDetailsQuery = (activeOrderUuid: string) => {
  const { id } = useParams();

  const {
    isLoading: isOrderDetailsLoading,
    data: orderDetailsRes,
    isError: isOrderDetailsError,
    error: orderDetailsError,
  } = useQuery<{ orderDetails: OrderDetails }, AxiosError<{ error: string }>>({
    queryKey: ["orderDetails", id, activeOrderUuid],
    queryFn: getOrderDetails(id, activeOrderUuid),
  });

  const orderDetails = orderDetailsRes?.orderDetails;

  return {
    isOrderDetailsLoading,
    orderDetails,
    isOrderDetailsError,
    orderDetailsError,
  };
};
