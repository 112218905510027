import { PromoDetails } from "utils/types/OrderDetails";
import { toDollars } from "utils/functions/toDollars";

export const displayPromotionAmount = ({
  promotionType,
  discountValue,
}: PromoDetails) =>
  promotionType === "percentage-discount"
    ? `${discountValue}%`
    : toDollars(discountValue);
