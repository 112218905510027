import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { initSentry } from "third-party/sentry";
import { initFullstory } from "third-party/fullstory";

import { App } from "./App";
import { ErrorBoundary } from "components/ErrorBoundary";

import { NotificationProvider } from "utils/contexts/notification";

import "./index.scss";

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Failed to find the root element");
}
const root = ReactDOM.createRoot(rootElement);
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

initSentry();
initFullstory();

root.render(
  <LDProvider clientSideID={import.meta.env.VITE_LAUNCHDARKLY_KEY as string}>
    <ErrorBoundary>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </LDProvider>
);
