import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { getInvoice } from "api/invoices";
import { Invoice } from "utils/types/Invoice";
import { useEffect, useState } from "react";

export type InvoiceResponseType = {
  businessDetails: {
    contactTelephone: string;
    logoUrl: string;
    businessPrimaryColor: string;
    businessId: number;
  };
  invoice: Invoice;
  isUuidInvalid: boolean;
};

export const useInvoiceQuery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isInvoiceChanged, setInvoiceChanged] = useState(false);

  const {
    isInitialLoading: isInvoiceLoading,
    data: invoiceRes,
    refetch: refetchInvoice,
  } = useQuery<InvoiceResponseType, AxiosError<{ error: string }>>({
    queryKey: ["invoice", id],
    queryFn: getInvoice(id),
    keepPreviousData: true,
    onError: ({ code, message, response }) => {
      navigate("/error", {
        state: {
          error: response?.status ?? code,
          message: response?.data.error ?? message,
        },
      });
    },
  });

  useEffect(() => {
    if (invoiceRes?.isUuidInvalid || invoiceRes?.invoice.isDeleted) {
      navigate("/invalid", { state: invoiceRes });
    }
  }, [invoiceRes]);

  if (isInvoiceChanged) {
    void refetchInvoice();
    setInvoiceChanged(false);
  }

  const invoice = invoiceRes?.invoice;
  const businessDetails = invoiceRes?.businessDetails;
  const isUuidInvalid = invoiceRes?.isUuidInvalid;

  return {
    isInvoiceLoading,
    invoice,
    businessDetails,
    isUuidInvalid,
    setInvoiceChanged,
    id,
  };
};
