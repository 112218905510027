import React, { useEffect, useState } from "react";
import { loadStripe, PaymentIntent } from "@stripe/stripe-js";

import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Loader } from "components/common/Loader";

import { useMutation } from "@tanstack/react-query";
import { useNotification } from "utils/hooks/useNotification";
import { useToggle } from "utils/hooks/useToggle";
import { usePaymentIntent } from "components/PaymentForm/usePaymentIntent";

import { updatePaymentApplicationFee } from "api/invoices";
import {
  PAYMENT_METHOD,
  STRIPE_PAYMENT_METHODS,
  STRIPE_PAYMENT_METHODS_KEY,
} from "utils/constants/STRIPE_PAYMENT_METHODS";
import { STRIPE_PAYMENT_STATUSES } from "utils/constants/STRIPE_PAYMENT_STATUSES";

import styles from "./styles.module.scss";

const stripe = loadStripe(import.meta.env.VITE_STRIPE_KEY as string);

const PAYMENT_VERIFICATION_INTERVAL = 2500;
const MAX_VERIFICATION_TIME = 60000;

type FormProps = {
  paymentIntent: PaymentIntent;
  setPaymentIntent: (value: PaymentIntent) => void;
  businessPrimaryColor: string;
  invoiceCode: string;
  setInvoiceChanged: (value: boolean) => void;
};

export const Form = ({
  paymentIntent,
  setPaymentIntent,
  businessPrimaryColor,
  invoiceCode,
  setInvoiceChanged,
}: FormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] =
    useState<STRIPE_PAYMENT_METHODS_KEY>("card");
  const { isOpen: paymentInProcessing, toggle: togglePaymentInProcessing } =
    useToggle(false);
  const [updateInvoice, setUpdateInvoice] = useState(false);

  const { setNotification } = useNotification();

  const { mutate: updatePaymentApplicationFeeMutation } = useMutation({
    mutationFn: async (paymentMethod: PAYMENT_METHOD) => {
      const response = await updatePaymentApplicationFee<{
        paymentIntent: PaymentIntent;
      }>(paymentIntent.id, paymentMethod)();
      return response.paymentIntent;
    },
    onSuccess: (paymentIntent) => setPaymentIntent(paymentIntent),
  });

  useEffect(() => {
    if (updateInvoice) {
      const interval = setInterval(
        () => setInvoiceChanged(true),
        PAYMENT_VERIFICATION_INTERVAL
      );
      const timeout = setTimeout(() => {
        clearInterval(interval);
        togglePaymentInProcessing();
        setNotification({
          title: "Error:",
          text: "invoice payment is processing too long, try reload the page",
          isError: true,
        });
      }, MAX_VERIFICATION_TIME);

      return () => {
        setNotification({
          title: invoiceCode,
          text:
            paymentMethod === "card"
              ? "successfully paid!"
              : "ACH Payment Submitted",
        });
        togglePaymentInProcessing();
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [
    updateInvoice,
    setNotification,
    togglePaymentInProcessing,
    paymentMethod,
  ]);

  const onPay = async () => {
    try {
      if (!stripe || !elements) {
        return;
      }
      togglePaymentInProcessing();
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (
        paymentIntent?.status === STRIPE_PAYMENT_STATUSES.SUCCEEDED ||
        paymentIntent?.status === STRIPE_PAYMENT_STATUSES.PROCESSING
      ) {
        setUpdateInvoice(true);
      }

      if (error) {
        togglePaymentInProcessing();
        setNotification({
          title: error.type,
          text: error.message,
          isError: true,
        });
      }
    } catch (error) {
      togglePaymentInProcessing();
      setNotification({
        title: "Error:",
        text: "invoice hasn't been paid due to the error",
        isError: true,
      });
    }
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: paymentIntent.currency,
    minimumFractionDigits: 2,
  });
  const payAmount = currencyFormatter.format(paymentIntent.amount / 100);

  const onPaymentElementChange = ({ value }: { value: { type: string } }) => {
    if (paymentMethod !== value.type) {
      const newPaymentMethod = value.type as STRIPE_PAYMENT_METHODS_KEY;
      updatePaymentApplicationFeeMutation(
        STRIPE_PAYMENT_METHODS[newPaymentMethod]
      );
      setPaymentMethod(newPaymentMethod);
    }
  };

  return (
    <div className={styles.form}>
      {paymentInProcessing && <Loader className={styles.loader} blocking />}
      <PaymentElement onChange={onPaymentElementChange} />
      <button
        style={{ backgroundColor: businessPrimaryColor }}
        className={styles.payButton}
        onClick={() => void onPay()}
      >
        Pay {payAmount}
      </button>
    </div>
  );
};

type PaymentFormProps = {
  businessPrimaryColor: string;
  invoiceCode: string;
  setInvoiceChanged: (value: boolean) => void;
};

export const PaymentForm = ({
  businessPrimaryColor,
  invoiceCode,
  setInvoiceChanged,
}: PaymentFormProps) => {
  const { paymentIntent, setPaymentIntent } = usePaymentIntent();

  if (!paymentIntent?.client_secret) {
    return null;
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: paymentIntent.client_secret,
        appearance: {
          theme: "stripe",
          variables: {
            colorPrimary: "#3790f4",
            colorText: "#303651",
            colorDanger: "#b00020",
            fontFamily: "Inter, sans-serif",
          },
          rules: {
            ".Input:focus": {
              border: "1px solid #3790f4",
              outline: "1px solid #3790f4",
              boxShadow: "initial",
            },
          },
        },
      }}
    >
      <Form
        paymentIntent={paymentIntent}
        setPaymentIntent={setPaymentIntent}
        businessPrimaryColor={businessPrimaryColor}
        invoiceCode={invoiceCode}
        setInvoiceChanged={setInvoiceChanged}
      />
    </Elements>
  );
};
