import { toDollars } from "utils/functions/toDollars";

export const getEachWayFee = (
  pickupFee: number,
  returnFee: number,
  onDemandProvider = false
) => {
  const isAround = onDemandProvider ? "~" : "";
  const pickupFeeInDollars = toDollars(pickupFee);

  if (pickupFee === returnFee) {
    return `${isAround}${pickupFeeInDollars} each way`;
  }

  return `${isAround}${pickupFeeInDollars} + ${isAround}${toDollars(
    returnFee
  )}`;
};
