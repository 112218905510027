import React, { ReactNode } from "react";

import { DriverTip } from "./DriverTip";

import { getEachWayFee } from "utils/functions/getEachWayFee";
import { getFinalSubsidy } from "utils/functions/getFinalSubsidy";
import { toDollars } from "utils/functions/toDollars";
import { getEachWaySubsidy } from "utils/functions/getEachWaySubsidy";
import { getPickupOrDelivery } from "utils/functions/getPickupOrDelivery";

import styles from "./styles.module.scss";

type OnDemandSummaryForPickupAndDeliveryProps = {
  pickupDeliveryFee: number;
  returnDeliveryFee: number;
  delivery?: { subsidyInCents: number; thirdPartyDeliveryCostInCents: number };
  pickup?: { subsidyInCents: number; thirdPartyDeliveryCostInCents: number };
  pickupDeliveryTip: number;
  returnDeliveryTip: number;
};

export const OnDemandSummaryForPickupAndDelivery = ({
  pickupDeliveryFee,
  returnDeliveryFee,
  delivery,
  pickup,
  pickupDeliveryTip,
  returnDeliveryTip,
}: OnDemandSummaryForPickupAndDeliveryProps) => {
  const pickupSubsidy = (pickup?.subsidyInCents ?? 0) / 100;
  const deliverySubsidy = (delivery?.subsidyInCents ?? 0) / 100;

  const thirdPartyPickupCost =
    (pickup?.thirdPartyDeliveryCostInCents ?? 0) / 100;
  const thirdPartyDeliveryCost =
    (delivery?.thirdPartyDeliveryCostInCents ?? 0) / 100;

  const finalPickupSubsidy = getFinalSubsidy(
    pickupSubsidy,
    thirdPartyPickupCost
  );
  const finalDeliverySubsidy = getFinalSubsidy(
    deliverySubsidy,
    thirdPartyDeliveryCost
  );

  const totalDeliveryCost = pickupDeliveryFee + returnDeliveryFee;
  const pickupCost = pickupDeliveryFee + finalPickupSubsidy;
  const deliveryCost = returnDeliveryFee + finalDeliverySubsidy;
  const tripCost = pickupCost + deliveryCost;

  return (
    <div className={styles.full}>
      <div className={styles.onDemandPaidInfo}>
        <p>On-Demand Pickup & Delivery</p>
        <p>{toDollars(totalDeliveryCost)}</p>
      </div>
      <div className={styles.italicText}>
        <p>Trip cost: {toDollars(tripCost)}</p>
        <p className={styles.textPaddingLeft}>
          {getPickupOrDelivery<string>({
            isPickup: Boolean(pickupDeliveryTip),
            isDelivery: Boolean(returnDeliveryTip),
            both: `(${getEachWayFee(pickupCost, deliveryCost, true)})`,
            pickup: `(pickup: ${toDollars(pickupCost)}, delivery: $0.00)`,
            delivery: `(pickup: $0.00, delivery: ${toDollars(deliveryCost)})`,
          })}
        </p>
      </div>
      {(finalPickupSubsidy !== 0 || finalDeliverySubsidy !== 0) && (
        <div className={styles.italicSubsidyText}>
          <p>
            Subsidy: -{toDollars(finalPickupSubsidy + finalDeliverySubsidy)}
          </p>
          <p className={styles.textPaddingLeft}>
            {getPickupOrDelivery<string>({
              isPickup: Boolean(pickupDeliveryTip),
              isDelivery: Boolean(returnDeliveryTip),
              both: `(${getEachWaySubsidy(
                finalPickupSubsidy,
                finalDeliverySubsidy
              )})`,
              pickup: `(pickup: -${toDollars(
                finalPickupSubsidy
              )}, delivery: $0.00)`,
              delivery: `(pickup: $0.00, delivery: -${toDollars(
                finalDeliverySubsidy
              )})`,
            })}
          </p>
        </div>
      )}
      {getPickupOrDelivery<ReactNode>({
        isPickup: Boolean(pickupDeliveryTip),
        isDelivery: Boolean(returnDeliveryTip),
        both: (
          <>
            <div className={styles.onDemandPaidInfo}>
              <p>Pickup & Delivery Driver Tips</p>
              <p>{toDollars(returnDeliveryTip && pickupDeliveryTip)}</p>
            </div>
            <div className={styles.italicText}>
              <p>Split evenly between both drivers</p>
            </div>
          </>
        ),
        pickup: <DriverTip prefix="Pickup" driverTip={pickupDeliveryTip} />,
        delivery: <DriverTip prefix="Delivery" driverTip={returnDeliveryTip} />,
      })}
    </div>
  );
};
