import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { getSummaryOrders } from "api/invoices";
import { InvoiceSummary } from "utils/types/InvoiceSummary";

export const useSummaryQuery = (isDetailsOpen: boolean) => {
  const { id } = useParams();

  const {
    isInitialLoading: isSummaryLoading,
    data: summaryRes,
    isError: isSummaryError,
    error: summaryError,
    refetch,
  } = useQuery<
    { summaryOrders: InvoiceSummary },
    AxiosError<{ error: string }>
  >({
    queryKey: ["summary", id],
    queryFn: getSummaryOrders(id),
    enabled: false,
  });

  useEffect(() => {
    if (isDetailsOpen) {
      refetch();
    }
  }, [isDetailsOpen, refetch]);

  const summary = summaryRes?.summaryOrders;

  return {
    isSummaryLoading,
    summary,
    isSummaryError,
    summaryError,
  };
};
