import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { PaymentIntent } from "@stripe/stripe-js";

import { getPaymentIntent } from "api/invoices";

export const usePaymentIntent = () => {
  const { id } = useParams();

  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();

  const { mutate: getPaymentIntentMutation } = useMutation({
    mutationFn: async () => {
      const { paymentIntent } = await getPaymentIntent<{
        paymentIntent: PaymentIntent;
      }>(id)();
      return paymentIntent;
    },
    onSuccess: (paymentIntent) => setPaymentIntent(paymentIntent),
  });

  useEffect(() => {
    getPaymentIntentMutation();
  }, [getPaymentIntentMutation]);

  return { paymentIntent, setPaymentIntent };
};
