import React from "react";

import { Loader } from "components/common/Loader";
import { ErrorPage } from "components/common/ErrorPage";
import { OrderSummaryHeader } from "./OrderSummaryHeader";
import { OrderSummaryItems } from "./OrderSummaryItems";
import { PaymentSummary } from "./PaymentSummary";

import { useOrderDetailsQuery } from "./useOrderDetailsQuery";

import closeIcon from "assets/images/close.svg";
import styles from "./styles.module.scss";

type OrderSummaryProps = {
  activeOrderUuid: string;
  onBack: () => void;
  onClose: () => void;
};

export const OrderSummary = ({
  activeOrderUuid,
  onBack,
  onClose,
}: OrderSummaryProps) => {
  const {
    isOrderDetailsLoading,
    orderDetails,
    isOrderDetailsError,
    orderDetailsError,
  } = useOrderDetailsQuery(activeOrderUuid);

  if (isOrderDetailsError && orderDetailsError) {
    const { code, message, response } = orderDetailsError;
    return (
      <>
        <ErrorPage
          error={response?.status ?? code}
          message={response?.data.error ?? message}
        />
        <img
          className={styles.closeIcon}
          onClick={onClose}
          src={closeIcon}
          alt="Close card"
        />
      </>
    );
  }

  if (isOrderDetailsLoading) {
    return <Loader />;
  }

  if (!orderDetails) {
    return null;
  }

  return (
    <div className={styles.card}>
      <OrderSummaryHeader orderDetails={orderDetails} onBack={onBack} />
      <OrderSummaryItems orderDetails={orderDetails} />
      <PaymentSummary orderDetails={orderDetails} />
    </div>
  );
};
