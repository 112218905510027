import React from "react";

import {toDollars} from "utils/functions/toDollars";
import {calculateItemTotal} from "utils/functions/calculateItemTotal";
import {getAmountPerUnit} from "utils/functions/getAmountPerUnit";
import {ORDER_ITEM_CATEGORIES} from "utils/constants/ORDER_ITEM_CATEGORIES";
import {ModifierLineItem, OrderDetails} from "utils/types/OrderDetails";

import styles from "./styles.module.scss";

type OrderSummaryItemsProps = {orderDetails: OrderDetails};

const formatModifier = (modifier: ModifierLineItem) => {
  const totalPrice = modifier.quantity * modifier.unitCost;
  const modifierUnit =
    modifier.modifierPricingType === ORDER_ITEM_CATEGORIES.PER_POUND ? "lb" : "unit";
  const quantityText =
    modifier.modifierPricingType === ORDER_ITEM_CATEGORIES.FIXED_PRICE
      ? `${modifier.quantity} x `
      : "";

  return `+ ${modifier.modifierName}: ${quantityText} ${toDollars(
    modifier.unitCost
  )} / ${modifierUnit} ${toDollars(totalPrice)}`;
};

export const OrderSummaryItems = ({orderDetails}: OrderSummaryItemsProps) => {
  const sortedOrderItems = orderDetails.orderItems.sort((first) =>
    first.category === ORDER_ITEM_CATEGORIES.DELIVERY ? -1 : 1
  );

  return (
    <div className={styles.items}>
      {sortedOrderItems.map((item) => (
        <div className={styles.itemWrapper} key={item.orderItemId}>
          <div className={styles.itemTitle}>
            <p>{item.laundryType}</p>
            <p>{calculateItemTotal(item)}</p>
          </div>
          <div className={styles.itemPriceInfo}>
            <p>{getAmountPerUnit(item)}</p>
          </div>
          {item.modifierLineItems.length !== 0 && (
            <div className={styles.itemPriceInfo}>
              {item.modifierLineItems.map((modifier) => (
                <p key={modifier.id}>{formatModifier(modifier)}</p>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
