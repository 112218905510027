import React from "react";

import { Invoice } from "utils/types/Invoice";
import { INVOICE_PAYMENT_STATUSES } from "utils/constants/INVOICE_PAYMENT_STATUSES";
import { STRIPE_PAYMENT_METHODS } from "utils/constants/STRIPE_PAYMENT_METHODS";

import styles from "components/DetailsCard/styles.module.scss";

type InvoiceDetailsCardInfoProps = {
  invoice: Invoice;
};

export const InvoiceDetailsCardInfo = ({
  invoice,
}: InvoiceDetailsCardInfoProps) => {
  const isInvoicePending =
    invoice.paymentStatus === INVOICE_PAYMENT_STATUSES.PENDING;
  const payment = invoice.invoicePayments[0];
  const paymentLabel =
    payment?.type === "ONLINE"
      ? `Paid Online${isInvoicePending ? " (Pending)" : ""}:`
      : "Payment Recorded:";
  const isPendingACH =
    payment?.paymentMethod === STRIPE_PAYMENT_METHODS.us_bank_account &&
    isInvoicePending;
  const amountAndPaymentMethod = `${payment?.amount} - ${
    isPendingACH ? "ACH Transfer Initiated" : payment?.readablePaymentMethod
  }`;

  return (
    <div className={styles.info}>
      {invoice.paymentStatus === INVOICE_PAYMENT_STATUSES.UNPAID ? (
        <>
          <p className={styles.data}>Invoice</p>
          <p className={styles.value}>{invoice.invoiceCode}</p>
          <p className={styles.data}>Due</p>
          <p className={styles.value}>{invoice.fullDueDate}</p>
          <p className={styles.data}>To</p>
          <p className={styles.value}>{invoice.to}</p>
          <p className={styles.data}>From</p>
          <p className={styles.value}>{invoice.from}</p>
          {invoice.invoiceMemo && (
            <>
              <hr className={styles.divider} />
              <p className={styles.data}>Memo</p>
              <p className={styles.value}>{invoice.invoiceMemo}</p>
            </>
          )}
        </>
      ) : (
        <>
          <p className={styles.data}>Invoice</p>
          <p className={styles.value}>{invoice.invoiceCode}</p>
          <p className={styles.data}>Paid</p>
          <div className={styles.value}>
            <p className={styles.bold}>{paymentLabel}</p>
            <p>{invoice.fullPaidDate}</p>
            <p>{amountAndPaymentMethod}</p>
            {isInvoicePending && (
              <p className={styles.postscript}>
                ACH payments take 2-5 business days to be processed.
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};
