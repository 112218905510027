import React from "react";

import { StandardSummary } from "./StandardSummary";
import { OnDemandOrderSummary } from "./OnDemandOrderSummary";
import { OnDemandSummaryForPickupAndDelivery } from "./OnDemandSummaryForPickupAndDelivery";

import { DELIVERY_PROVIDERS } from "utils/constants/DELIVERY_PROVIDERS";
import { OrderDetails } from "utils/types/OrderDetails";

import styles from "./styles.module.scss";

type PickupDeliveryOrderSummaryProps = { orderDetails: OrderDetails };

export const PickupDeliveryOrderSummary = ({
  orderDetails,
}: PickupDeliveryOrderSummaryProps) => {
  const {
    pickup,
    delivery,
    pickupDeliveryFee,
    returnDeliveryFee,
    pickupDeliveryTip,
    returnDeliveryTip,
  } = orderDetails;
  const pickupProvider = pickup.deliveryProvider;
  const deliveryProvider = delivery.deliveryProvider;

  switch (true) {
    case !pickupProvider && deliveryProvider === DELIVERY_PROVIDERS.OWN_DRIVER:
      return (
        <StandardSummary
          pickupProvider={pickupProvider}
          deliveryProvider={deliveryProvider}
          pickupDeliveryFee={0}
          returnDeliveryFee={returnDeliveryFee}
        />
      );
    case !pickupProvider &&
      deliveryProvider &&
      deliveryProvider !== DELIVERY_PROVIDERS.OWN_DRIVER:
      return (
        <OnDemandOrderSummary
          type="Delivery"
          deliveryFee={returnDeliveryFee}
          driverTip={returnDeliveryTip}
          orderDelivery={delivery}
        />
      );
    case pickupProvider === DELIVERY_PROVIDERS.OWN_DRIVER &&
      (!deliveryProvider || deliveryProvider === DELIVERY_PROVIDERS.OWN_DRIVER):
      return (
        <StandardSummary
          pickupProvider={pickupProvider}
          deliveryProvider={deliveryProvider}
          pickupDeliveryFee={pickupDeliveryFee}
          returnDeliveryFee={returnDeliveryFee}
        />
      );
    case pickupProvider !== DELIVERY_PROVIDERS.OWN_DRIVER &&
      (!deliveryProvider || deliveryProvider === DELIVERY_PROVIDERS.OWN_DRIVER):
      return (
        <div className={styles.full}>
          <OnDemandOrderSummary
            type="Pickup"
            deliveryFee={pickupDeliveryFee}
            driverTip={pickupDeliveryTip}
            orderDelivery={pickup}
          />
          {deliveryProvider && (
            <StandardSummary
              deliveryProvider={deliveryProvider}
              pickupDeliveryFee={0}
              returnDeliveryFee={returnDeliveryFee}
            />
          )}
        </div>
      );
    case pickupProvider === DELIVERY_PROVIDERS.OWN_DRIVER &&
      deliveryProvider !== DELIVERY_PROVIDERS.OWN_DRIVER:
      return (
        <div className={styles.full}>
          <StandardSummary
            pickupProvider={pickupProvider}
            pickupDeliveryFee={pickupDeliveryFee}
            returnDeliveryFee={0}
          />
          <OnDemandOrderSummary
            type="Delivery"
            deliveryFee={returnDeliveryFee}
            driverTip={returnDeliveryTip}
            orderDelivery={delivery}
          />
        </div>
      );
    default:
      return (
        <OnDemandSummaryForPickupAndDelivery
          pickupDeliveryFee={pickupDeliveryFee}
          returnDeliveryFee={returnDeliveryFee}
          pickupDeliveryTip={pickupDeliveryTip}
          returnDeliveryTip={returnDeliveryTip}
          pickup={pickup}
          delivery={delivery}
        />
      );
  }
};
