import React from "react";

import { toDollars } from "utils/functions/toDollars";

import styles from "./styles.module.scss";

type DriverTipProps = { prefix: string; driverTip: number };

export const DriverTip = ({ prefix, driverTip }: DriverTipProps) => (
  <div className={styles.paidInfo}>
    <p>{prefix} Driver Tip</p>
    <p>{toDollars(driverTip)}</p>
  </div>
);
