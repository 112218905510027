import { toDollars } from "utils/functions/toDollars";

export const getEachWaySubsidy = (
  pickupSubsidy: number,
  returnSubsidy: number
) => {
  const amount = toDollars(pickupSubsidy);

  if (pickupSubsidy === returnSubsidy) {
    return `-${amount} each way`;
  }

  return `-${amount}, -${toDollars(returnSubsidy)}`;
};
